import "core-js/modules/es.array.push.js";
import LayoutRight from "@/components/FixedLayout/index.vue";
import { getBanners } from "@/api/index.js";
export default {
  components: {
    LayoutRight
  },
  data() {
    return {
      showNextMenu: false,
      bannerVisible: false,
      height: 0,
      showRight: false,
      groupList: [{
        show: false,
        icon: require("@/assets/fixedRight/group1.png"),
        name: "成员机构",
        child: ["中建政研集团陕西分公司", "中建政研集团陕西分公司", "中建政研集团陕西分公司", "中建政研集团陕西分公司"]
      },
      // {show:false,icon:require('@/assets/fixedRight/group2.png'),name:'区域公司',child:['中建政研集团陕西分公司','中建政研集团陕西分公司','中建政研集团陕西分公司','中建政研集团陕西分公司']},
      {
        show: false,
        icon: require("@/assets/fixedRight/group3.png"),
        name: "直属事业部",
        child: ["中建政研集团陕西分公司", "中建政研集团陕西分公司", "中建政研集团陕西分公司", "中建政研集团陕西分公司"]
      }
      // {show:false,icon:require('@/assets/fixedRight/group4.png'),name:'圆梦商学院',child:['中建政研集团陕西分公司','中建政研集团陕西分公司','中建政研集团陕西分公司','中建政研集团陕西分公司']},
      // {show:false,icon:require('@/assets/fixedRight/group5.png'),name:'建企荟俱乐部',child:['中建政研集团陕西分公司','中建政研集团陕西分公司','中建政研集团陕西分公司','中建政研集团陕西分公司']},
      ],

      fixedList: [{
        img: require("@/assets/fixedRight/icon1.png"),
        name: "投建云"
      }, {
        img: require("@/assets/fixedRight/icon2.png"),
        name: "投建云贴"
      }, {
        img: require("@/assets/fixedRight/icon3.png"),
        name: "投建云信"
      }, {
        img: require("@/assets/fixedRight/icon4.png"),
        name: "投建云采"
      }],
      addDropIndex: null,
      headDropMenu: [],
      headDrop: {
        head_menu1: [{
          title: "集团介绍",
          path: "/enterUs",
          tabs: 1,
          child: []
        }, {
          title: "创始人介绍",
          path: "/createPersonInfo",
          tabs: 1,
          child: []
        }, {
          title: "发展历程",
          path: "/development",
          tabs: 1,
          child: []
        }, {
          title: "资质荣誉",
          path: "/honor",
          tabs: 1,
          child: []
        }
        // {
        //   title: "新型智库",
        //   path: "/viewpoint",
        //   tabs: 1,
        //   child: [
        //     { name: "专家团队", btnIndex: "first", path: "/viewpoint" },
        //     { name: "智库观点", btnIndex: "second", path: "/viewpoint" },
        //   ],
        // },
        // {
        //     title:'客户见证',
        //     path:'/enterUs',
        //     tabs:1,
        //     child:[]
        // },

        // {
        //     title:'产品集群',
        //     path:'/brands',
        //     tabs:1,
        //     child:[
        //         {name:'投建云',btnIndex:null,link:'https://web.toujianyun.com/'},
        //         {name:'投建云贴',btnIndex:null,link:''},
        //         {name:'投建云信',btnIndex:null,link:''},
        //         {name:'投建云采',btnIndex:null,link:''},
        //         {name:'中研企课堂',btnIndex:null,link:'https://ent.toujianyun.com/'},
        //         {name:'中研云课堂',btnIndex:null,link:'https://edu.toujianyun.com/'},
        //     ]
        // },
        ],

        head_menu2: [{
          title: "新闻动态",
          path: "/mediaCenter",
          btnIndex: "0",
          child: [
            // {name:'集团要闻',btnIndex:'1'},
            // {name:'行业联播',btnIndex:'2'},
            // {name:'媒体聚焦',btnIndex:'3'},
          ]
        }, {
          title: "行业研判",
          path: "/mediaCenter",
          btnIndex: "1",
          child: [
            // {name:'党组织建设',btnIndex:'1'},
            // {name:'工会风采',btnIndex:'3'},
          ]
        }
        // {
        //     title:'智库观点',
        //     path:'/mediaCenter',
        //     tabs:3,
        //     child:[]
        // },
        // {
        //   title: "集团自媒体",
        //   path: "/mediaCenter",
        //   btnIndex: "2",
        //   child: [
        //     // {name:'官方账号',btnIndex:'1'},
        //     // {name:'影像故事',btnIndex:'2'},
        //   ],
        // },
        ],

        head_menu3: [{
          title: "投建云",
          path: "https://web.toujianyun.com/"
        }, {
          title: "投建云贴",
          path: ""
        }, {
          title: "投建云信",
          path: ""
        }, {
          title: "投建云采",
          path: ""
        }, {
          title: "中研企课堂",
          path: "https://ent.toujianyun.com/"
        }, {
          title: "中研云课堂",
          path: "https://edu.toujianyun.com/"
        }],
        head_menu4: [{
          title: "联系方式",
          path: "/connectUs"
        }, {
          title: "加入我们",
          path: "/addUs"
        }]
      },
      headList: [{
        path: "/",
        menu: "首页"
      }, {
        path: "/enterUs",
        menu: "走进中建政研"
      }, {
        path: "/viewpoint",
        menu: "新型智库"
      }, {
        path: "/mediaCenter",
        menu: "集团要闻"
      }, {
        path: "/brands",
        menu: "产品集群"
      },
      // { path: "/addUs", menu: "加入我们" },
      {
        path: "/connectUsIcons",
        menu: "联系我们"
      }],
      activeIndex: 0
    };
  },
  computed: {
    activeMenu() {}
  },
  watch: {
    $route: {
      handler(to, from) {
        if (close !== "CLOSE" && this.$route.path === "/") {
          this.bannerVisible = true;
        } else {
          this.bannerVisible = false;
          const elc = document.getElementById("page_container");
          if (elc) {
            elc.setAttribute("style", `padding-top: ${71}px !important`);
          }
          const ael = document.getElementById("app-head");
          if (ael) {
            ael.setAttribute("style", `top: ${0}px !important`);
          }
        }
        // console.log(to)
        if (to.path == "/") {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 0);
          this.activeIndex = 0;
        }
        if (to.path == "/enterUs" || to.path == "/createPersonInfo" || to.path == "/development" || to.path == "/honor" // ||
        // to.path == "/viewpoint" ||
        // to.path == "/viewpointDetails"
        ) {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 1);
          this.activeIndex = 1;
        }
        if (to.path == "/viewpoint" || to.path == "/viewpointDetails") {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 2);
          this.activeIndex = 2;
        }
        if (to.path == "/mediaCenter" || to.path == "/mediaCenterDetails") {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 3);
          this.activeIndex = 3;
        }
        if (to.path == "/brands" || to.path == "/brandsDetails") {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 4);
          this.activeIndex = 4;
        }
        // if (to.path == "/addUs" || to.path == "/addUsDetails") {
        //   sessionStorage.setItem("HEAD_ACTIVE_INDEX", 5);
        //   this.activeIndex = 5;
        // }
        // if (to.path == "/connectUs") {
        //   sessionStorage.setItem("HEAD_ACTIVE_INDEX", 6);
        //   this.activeIndex = 6;
        // }
        if (to.path == "/connectUsIcons") {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 5);
          this.activeIndex = 5;
        }
        this.showRight = false;
      },
      immediate: true
    }
  },
  created() {
    // const close = localStorage.getItem("BANNER_VISIBLE");
    // console.info(close)
    // if (close !== "CLOSE" && this.$route.path === "/") {
    //   this.bannerVisible = true;
    // }else {
    //     this.bannerVisible = false;
    // }
    if (sessionStorage.getItem("HEAD_ACTIVE_INDEX")) {
      this.activeIndex = sessionStorage.getItem("HEAD_ACTIVE_INDEX");
    }
    this.init();
  },
  methods: {
    // 关闭banner
    handleCloseBanner() {
      localStorage.setItem("BANNER_VISIBLE", "CLOSE");
      this.bannerVisible = false;
      const elc = document.getElementById("page_container");
      elc.setAttribute("style", `padding-top: ${71}px !important`);
      const ael = document.getElementById("app-head");
      ael.setAttribute("style", `top: ${0}px !important`);
    },
    // banner 加载完成
    handleBannerLoaded() {
      const el = document.getElementById("banner-id");
      const height = el.clientHeight;
      const elc = document.getElementById("page_container");
      elc.setAttribute("style", `padding-top: ${height}px !important`);
      const ael = document.getElementById("app-head");
      ael.setAttribute("style", `top: ${height}px !important`);
    },
    jump(item) {
      if (item.link) {
        window.open(item.link, "_blank");
      } else {
        let {
          href
        } = this.$router.resolve({
          path: "/brandsDetails",
          query: {
            id: item.id
          }
        });
        window.open(href, "_blank");
      }
    },
    clickChildRow(item) {
      // console.log(item)
      // return
      if (item.link) {
        window.open(item.link, "_blank");
      } else if (item.contentDescription) {
        window.open(item.contentDescription, "_blank");
      } else {
        if (item.id) {
          // console.log(item)
          // sessionStorage.setItem('MEDIA_CENTER_DETAILS',item.contentDetails)
          // const {href} = this.$router.resolve({path:'/company'})
          // window.open(href,'_blank')
          let {
            href
          } = this.$router.resolve({
            path: "/brandsDetails",
            query: {
              id: item.id
            }
          });
          window.open(href, "_blank");
        }
      }
      if (item.contentDetails) {
        // console.log(item)
        // sessionStorage.setItem('MEDIA_CENTER_DETAILS',item.contentDetails)
        // let {href} =this.$router.resolve({path:'/brandsDetails',query:{title:item.contentTitle,time:item.time}})
        // window.open(href,'_blank')

        let {
          href
        } = this.$router.resolve({
          path: "/brandsDetails",
          query: {
            id: item.id
          }
        });
        window.open(href, "_blank");
      }
      if (item.path) {
        if (item.path == "/mediaCenter") {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 2);
          this.activeIndex = 2;
          if (item.btnIndex) {
            this.$router.push({
              path: item.path,
              query: {
                tabIndex: item.btnIndex,
                name: item.name
              }
            });
          } else {
            this.$router.push({
              path: item.path
            });
          }
        }
        if (item.path == "/viewpoint" || item.path == "/enterUs" || item.path == "/createPersonInfo" || item.path == "/development" || item.path == "/honor" || item.path == "/brands") {
          sessionStorage.setItem("HEAD_ACTIVE_INDEX", 1);
          this.activeIndex = 1;
          if (item.path == "/viewpoint") {
            this.$router.push({
              path: item.path,
              query: {
                tabIndex: item.btnIndex
              }
            });
          } else {
            this.$router.push(item.path);
          }
        }
      }
    },
    init() {
      // 产品集群
      getBanners("/cms/content/list.do", "post", {
        categoryId: "1524933899461029890"
      }).then(res => {
        this.headDrop.head_menu3 = res.data.rows.map(item => {
          // console.log(item,'res')
          if (item && item.contentImg) {
            item.contentImg = JSON.parse(item.contentImg);
          } else {
            item.contentImg = require("@/assets/default_with_font.png");
          }
          if (item && item.contentTitle) {
            this.$set(item, "title", item.contentTitle || "");
          } else {
            this.$set(item, "title", "");
          }
          // this.$set(item,'title',item.contentTitle)
          this.$set(item, "link", item && item.contentDescription || "");
          this.$set(item, "img", item && item.contentDetails || "");
          // if(item.contentDescription.includes('http')){
          //     this.$set(item,'link',item.contentDescription)
          // }else{
          //     if(item.contentDescription){
          //         this.$set(item,'link',"http://"+item.contentDescription)
          //     }else{
          //         this.$set(item,'link','')
          //     }
          // }
          // console.log(item)
          return item;
        });
      });
      let arrId = ["1524935942401650689",
      // '1524935949926232066',
      "1524935956620341250"
      // '1524936115035009026',
      // '1524936121943027713'
      ];

      arrId.forEach((item, index) => {
        getBanners("/cms/content/list", "post", {
          categoryId: item,
          pageSize: 100
        }).then(res => {
          if (index < 3) {
            this.groupList[index].child = res.data.rows.map(item => {
              // console.log(this.groupList[index])
              // console.log(item,'123')
              return item;
              // return {time:item.contentDatetime,name:item.contentTitle || '',link:item.contentDescription,contentDetails:item.contentDetails,contentTitle:item.contentTitle || '',}
            });
          }
        });
      });

      getBanners("/cms/content/list.do", "post", {
        categoryId: "1524933899461029890"
      }).then(res => {
        let list = res.data.rows.map(item => {
          if (item.contentImg) {
            item.contentImg = JSON.parse(item.contentImg);
            if (item.contentImg[0]) {
              item.contentImg = item.contentImg[0].path;
            } else {
              item.contentImg = require("@/assets/layouts/head_logo.png");
            }
          } else {
            item.contentImg = require("@/assets/default_with_font.png");
          }
          if (item.logoImg) {
            item.logoImg = JSON.parse(item.logoImg);
            if (item.logoImg[0]) {
              item.logoImg = item.logoImg[0].path;
            } else {
              item.logoImg = require("@/assets/layouts/head_logo.png");
            }
          } else {
            item.logoImg = require("@/assets/layouts/head_logo.png");
          }
          this.$set(item, "name", item.contentTitle || "");
          this.$set(item, "link", item.contentDescription || "");
          return item;
        });
        // this.headDrop.head_menu1[5].child = Object.assign({},list)
        this.fixedList = Object.assign({}, list);
        // console.log(this.fixedList)
      });
      // getBanners('/cms/content/list','post',{categoryId:'1524935949926232066',pageSize:100}).then(res=>{
      //     this.groupList[1].child = res.data.rows.map(item => {
      //         return {name:item.contentTitle,link:item.contentDescription}
      //     })
      // })
      // getBanners('/cms/content/list','post',{categoryId:'1524935956620341250',pageSize:100}).then(res=>{
      //     this.groupList[2].child = res.data.rows.map(item => {
      //         return {name:item.contentTitle,link:item.contentDescription}
      //     })
      // })
      // getBanners('/cms/content/list','post',{categoryId:'1524936115035009026',pageSize:100}).then(res=>{
      //     this.groupList[3].child = res.data.rows.map(item => {
      //         return {name:item.contentTitle,link:item.contentDescription}
      //     })
      // })
      // getBanners('/cms/content/list','post',{categoryId:'1524936121943027713',pageSize:100}).then(res=>{
      //     this.groupList[4].child = res.data.rows.map(item => {
      //         return {name:item.contentTitle,link:item.contentDescription}
      //     })
      // })
    },

    showRightFixed() {
      this.showRight = !this.showRight;
    },
    showChild(item, index) {
      item.show = !item.show;
    },
    jumpNext(items, index, item) {
      if (item.path == "/enterUs") {
        sessionStorage.setItem("HEAD_ACTIVE_INDEX", 1);
        this.activeIndex = 1;
        this.$router.push({
          path: items.path
        });
      } else if (item.path == "/mediaCenter") {
        sessionStorage.setItem("HEAD_ACTIVE_INDEX", 2);
        this.activeIndex = 2;
        this.$router.push({
          path: items.path,
          query: {
            tabIndex: items.btnIndex
          }
        });
      } else if (item.path == "/brands") {
        sessionStorage.setItem("HEAD_ACTIVE_INDEX", 3);
        this.activeIndex = 3;
        if (items.link) {
          if (items.link.split(".").length >= 2) {
            window.open(items.link, "_blank");
          }
        } else if (items.contentDetails) {
          // console.log(items)
          // return
          // sessionStorage.setItem('MEDIA_CENTER_DETAILS',items.contentDetails)
          // const {href} = this.$router.resolve({path:'/company'})
          // window.open(href,'_blank')
          let {
            href
          } = this.$router.resolve({
            path: "/brandsDetails",
            query: {
              id: items.id
            }
          });
          window.open(href, "_blank");
        }
      } else if (item.path == "/connectUsIcons") {
        sessionStorage.setItem("HEAD_ACTIVE_INDEX", 5);
        this.activeIndex = 5;
        this.$router.push({
          path: items.path,
          query: {
            tabIndex: items.btnIndex
          }
        });
      }
      // if(items.link){
      //     window.open(items.link,'_blank')
      // }
      // if(items.path.includes('http')){
      //     if(item.path.includes('http')){
      //         window.open(items.path,'_blank')
      //     }else{
      //         window.open('http://'+items.path,'_blank')
      //     }
      // }
    },

    clickHead(item, index) {
      this.activeIndex = index;
      sessionStorage.setItem("HEAD_ACTIVE_INDEX", this.activeIndex);
      this.$router.push(item.path);
    },
    mouseEnter(item, index) {
      if (item.menu == "走进中建政研") {
        this.addDropIndex = index;
        this.headDropMenu = this.headDrop.head_menu1;
        this.showNextMenu = true;
      } else if (item.menu == "集团要闻") {
        this.addDropIndex = index;
        this.headDropMenu = this.headDrop.head_menu2;
        this.showNextMenu = true;
      } else if (item.menu == "产品集群") {
        this.addDropIndex = index;
        this.headDropMenu = this.headDrop.head_menu3;
        this.showNextMenu = true;
      } else if (item.menu == "联系我们") {
        this.addDropIndex = index;
        this.headDropMenu = this.headDrop.head_menu4;
        this.showNextMenu = true;
      } else {
        this.dropMenuOut();
      }
    },
    mouseLeaveMenu() {
      this.dropMenuOut();
    },
    dropMenuOut() {
      this.$nextTick(() => {
        this.headDropMenu = [];
        this.addDropIndex = null;
        this.showNextMenu = false;
      });
    }
  }
};