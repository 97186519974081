export default {
  mounted() {
    window.addEventListener("load", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });

    // console.log(this.$store.state.userAgent,'1')
    if (this._isMobile() == "mobile") {
      this.$store.commit("SET_USER_AGENT", "mobile");
      console.log("手机");
    } else {
      this.$store.commit("SET_USER_AGENT", "PC");
      console.log("PC");
    }
  },
  methods: {
    _isMobile() {
      // let userAgent = navigator.userAgent.toLowerCase();
      // console.log(userAgent)
      // if(/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
      //   return 'mobile'; // 移动端
      // }else{
      //   return 'PC'; // PC端
      // }

      var browser = {
        versions: function () {
          var u = navigator.userAgent,
            app = navigator.appVersion;
          return {
            //移动终端浏览器版本信息
            trident: u.indexOf("Trident") > -1,
            //IE内核
            presto: u.indexOf("Presto") > -1,
            //opera内核
            webKit: u.indexOf("AppleWebKit") > -1,
            //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1,
            //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/),
            //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
            //android终端或者uc浏览器
            iPhone: u.indexOf("iPhone") > -1,
            //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1,
            //是否iPad
            webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部
          };
        }(),

        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      };
      if (browser.versions.mobile || browser.versions.ios || browser.versions.android || browser.versions.iPhone || browser.versions.iPad) {
        // console.log(browser.versions)
        return "mobile";
      }
    }
  }
};