var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "app-footer"
  }, [_c('div', {
    staticClass: "footers"
  }, [_c('div', {
    staticClass: "intro"
  }, [_vm._m(0), _c('div', {
    staticClass: "intro_right"
  }, [_vm._l(_vm.footerList, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "footer_title",
      staticStyle: {
        "margin-bottom": "20px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.jumps(item.title);
        }
      }
    }, [_vm._v(_vm._s(item.title.name))]), _c('ul', _vm._l(item.child, function (items, indexs) {
      return _c('li', {
        key: indexs,
        staticClass: "footer_font",
        staticStyle: {
          "margin-bottom": "20px",
          "cursor": "pointer"
        },
        on: {
          "click": function ($event) {
            return _vm.jumps(items);
          }
        }
      }, [_vm._v(_vm._s(items.name))]);
    }), 0)]);
  }), _c('div', [_c('div', {
    staticClass: "footer_title"
  }, [_vm._v("关注我们")]), _c('div', {
    staticClass: "footer_icons"
  }, [_c('el-popover', {
    attrs: {
      "close-delay": 0,
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticStyle: {
      "font-family": "SY"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("客服电话：400-653-0288")]), _c('div', {
    staticClass: "time"
  }, [_vm._v("（周一至周五：08:30-17:30）")])]), _c('div', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon_DH.png"),
      "alt": "电话"
    }
  })])]), _c('el-popover', {
    attrs: {
      "close-delay": 0,
      "width": 450,
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticClass: "wxs"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/fuwuhao.jpg"),
      "alt": ""
    }
  }), _c('div', [_vm._v("中建政研服务号")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/dingyue.png"),
      "alt": ""
    }
  }), _c('div', [_vm._v("中建政研订阅号")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/shipin.png"),
      "alt": ""
    }
  }), _c('div', [_vm._v("中建政研视频号")])])]), _c('div', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon_WX.png"),
      "alt": "微信"
    }
  })])]), _c('img', {
    attrs: {
      "src": require("@/assets/icons/icon_WB.png"),
      "alt": "微博"
    },
    on: {
      "click": _vm.jumpWB
    }
  })], 1)])], 2)]), _c('div', {
    staticClass: "manages",
    on: {
      "click": _vm.jump
    }
  }, [_vm._v(" ©2022 中建政研集团 ALL Rights Reserved.   京ICP备17059785号-1 ")])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "intro_left"
  }, [_c('div', {
    staticClass: "left_fonts"
  }, [_c('div', {
    staticClass: "footer_title"
  }, [_vm._v("联系我们")]), _c('div', {
    staticClass: "tel"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/footer_tel.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("400-653-0288")])]), _c('div', {
    staticClass: "footer_font",
    staticStyle: {
      "margin-top": "33px",
      "margin-bottom": "18px"
    }
  }, [_vm._v("邮编：100143")]), _c('div', {
    staticClass: "footer_font"
  }, [_vm._v("地址：北京市石景山区古城南街绿地金融城9号院1号楼5层")]), _c('div', {
    staticClass: "footer_WX"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/fuwuhao.jpg"),
      "alt": ""
    }
  })])])]);
}];
export { render, staticRenderFns };