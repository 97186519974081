import Vue from 'vue'
// import store from '@/store'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout/index.vue'


const originPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/index.vue')
      },
      {
        path: '/menu',
        name: 'menu',
        component: () => import('@/views/menuPage.vue'),
      },
      {
        path: '/addUs',
        name: 'addUs',
        component: () => import('@/views/addUs/addUs.vue')
      },
      {
        path: '/addUsDetails',
        name: 'addUsDetails',
        component: () => import('@/views/addUs/addUsDetails.vue')
      },
      {
        path: '/enterUs',
        name: 'enterUs',
        component: () => import('@/views/enterUs/index.vue')
      },
      {
        path: '/createPersonInfo',
        name: 'createPersonInfo',
        component: () => import('@/views/enterUs/enterUsCreate.vue')
      },
      {
        path: '/createPersonInfoShowMoreList',
        name: 'createPersonInfoShowMoreList',
        component: () => import('@/views/enterUs/enterUsCreateShowMoreList.vue')
      },
      {
        path: '/development',
        name: 'development',
        component: () => import('@/views/enterUs/development.vue')
      },
      {
        path: '/honor',
        name: 'honor',
        component: () => import('@/views/enterUs/honor.vue')
      },
      {
        path: '/viewpoint',
        name: 'viewpoint',
        component: () => import('@/views/enterUs/viewpoint.vue')
      },
      {
        path: '/brands',
        name: 'brands',
        component: () => import('@/views/enterUs/brands.vue')
      },
      {
        path: '/brandsDetails',
        name: 'brandsDetails',
        component: () => import('@/views/enterUs/brandDetails.vue')
      },
      {
        path: '/viewpointDetails',
        name: 'viewpointDetails',
        component: () => import('@/views/enterUs/viewpointDetails.vue')
      },
      {
        path: '/mediaCenter',
        name: 'mediaCenter',
        component: () => import('@/views/mediaCenter/index.vue')
      },
      {
        path: '/mediaCenterDetails',
        name: 'mediaCenterDetails',
        component: () => import('@/views/mediaCenter/mediaDetails.vue')
      },
      {
        path: '/connectUs',
        name: 'connectUs',
        component: () => import('@/views/connectUs/index.vue')
      },
      {
        path: '/connectUsIcons',
        name: 'connectUsIcons',
        component: () => import('@/views/connectUs/iconsPage.vue')
      },
      // 临时活动页面
      {
        path: '/temporary',
        name: 'temporary',
        component: () => import('@/views/temporaryPage/chineseYearReport2022/index.vue')
      },
      // 20230116新增 行业方法论 模块
      {
        path: '/indutryMethods',
        name: 'indutryMethods',
        component: () => import('@/views/industryMethods/index.vue')
      },
      {
        path: '/indutryMethodsDetails',
        name: 'indutryMethodsDetails',
        component: () => import('@/views/industryMethods/details.vue')
      },
      // 20230322新增 圆梦商学院 城市分院合伙人招募计划
      {
        path: '/cityPartner',
        name: 'cityPartner',
        component: () => import('@/views/cityPartner/index.vue')
      },
      {
        path: '/ceoClass',
        name: '/ceoClass',
        component: () => import('@/views/ceoClass/index.vue')
      },
      {
        path: '/teacherDetails',
        name: '/teacherDetails',
        component: () => import('@/views/ceoClass/teacherDetails.vue')
      },
      {
        path: '/ceoClassApply',
        name: '/ceoClassApply',
        component: () => import('@/views/ceoClass/apply.vue')
      },
      {
        path: '/testkefu',
        name: '/testkefu',
        component: () => import('@/views/testkefu.vue')
      },
      {
        path: '/ourCustomers',
        name: '/ourCustomers',
        component: () => import('@/views/components/otherPage/ourCustomerListPage.vue')
      },
    ]
  },
  // H5表单提交页面
  {
    path: '/submitForm',
    name: 'submitForm',
    component: () => import('@/views/connectUs/submitForm.vue')
  },
  // 移动端菜单页面
  // {
  //   path:'/menu',
  //   name:'menu',
  //   component:LayoutMobile,
  //   children:[
  //     {
  //       path:'/menu',
  //       name:'menu',
  //       component:() => import('@/views/menuPage.vue'),
  //     },
  //   ],
  // },
  {
    path: '/priview',
    name: 'priview',
    component: () => import('@/views/enterUs/priview.vue')
  },
  {
    path: '/company',
    name: 'connectUsIcons',
    component: () => import('@/views/company.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next()
})

export default router
