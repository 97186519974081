var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fixed_right"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      on: {
        "mouseenter": function ($event) {
          if ($event.target !== $event.currentTarget) return null;
          return _vm.hoverLeft(item, index);
        },
        "mouseleave": function ($event) {
          if ($event.target !== $event.currentTarget) return null;
          _vm.hoverLeftIndex = null;
        }
      }
    }, [index != 0 && index != 3 && index != 4 ? _c('el-popover', {
      attrs: {
        "close-delay": 0,
        "placement": "left",
        "title": "",
        "width": index == 2 ? 450 : 200,
        "trigger": "hover"
      }
    }, [index == 0 ? _c('div', {
      staticClass: "list_1"
    }, _vm._l(_vm.item1, function (itemImg, indexImg) {
      return _c('el-tooltip', {
        key: indexImg,
        staticClass: "item",
        attrs: {
          "enterable": false,
          "manual": false,
          "effect": "dark",
          "content": `${indexImg == 0 ? '企/事业单位用户联系' : indexImg == 1 ? '个人用户联系' : indexImg == 2 ? '媒体联系' : '招聘联系'}`,
          "placement": "bottom",
          "transition": "none"
        }
      }, [_c('img', {
        attrs: {
          "src": itemImg ? _vm.hoverIndex == indexImg ? itemImg.active : itemImg.usual : '',
          "alt": ""
        },
        on: {
          "mouseleave": function ($event) {
            _vm.hoverIndex = null;
          },
          "mouseenter": function ($event) {
            return _vm.showActiveHover(indexImg);
          },
          "click": function ($event) {
            return _vm.showConnects(indexImg);
          }
        }
      })]);
    }), 1) : _vm._e(), index == 1 ? _c('div', {
      staticClass: "list_2"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v("客服电话：400-653-0288")]), _c('div', {
      staticClass: "time"
    }, [_vm._v("（周一至周五：08:30-17:30）")])]) : _vm._e(), index == 2 ? _c('div', {
      staticClass: "list_3"
    }, [_c('div', [_c('img', {
      attrs: {
        "src": require("@/assets/joinUs/fuwuhao.jpg"),
        "alt": ""
      }
    }), _c('div', [_vm._v("中建政研服务号")])]), _c('div', [_c('img', {
      attrs: {
        "src": require("@/assets/joinUs/dingyue.png"),
        "alt": ""
      }
    }), _c('div', [_vm._v("中建政研订阅号")])]), _c('div', [_c('img', {
      attrs: {
        "src": require("@/assets/joinUs/shipin.png"),
        "alt": ""
      }
    }), _c('div', [_vm._v("中建政研视频号")])])]) : _vm._e(), _c('div', {
      staticClass: "popover_item",
      attrs: {
        "slot": "reference"
      },
      slot: "reference"
    }, [_c('img', {
      attrs: {
        "src": _vm.hoverLeftIndex == index ? item.icona : item.icon,
        "alt": ""
      }
    }), _c('div', [_vm._v(_vm._s(item.name))])])]) : _c('div', {
      staticClass: "popover_item",
      attrs: {
        "slot": "reference"
      },
      on: {
        "click": function ($event) {
          return _vm.scrollTop(item, index);
        }
      },
      slot: "reference"
    }, [_c('img', {
      attrs: {
        "src": _vm.hoverLeftIndex == index ? item.icona : item.icon,
        "alt": ""
      }
    }), _c('div', [_vm._v(_vm._s(item.name))])])], 1);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };