var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page_container",
    style: `padding-top:${_vm.$store.state.userAgent == 'PC' ? '71px' : '50px'}`,
    attrs: {
      "id": "page_container"
    }
  }, [_vm.$store.state.userAgent == 'PC' ? _c('Head', {
    staticClass: "head"
  }) : _vm.$store.state.userAgent == 'mobile' ? _c('HeadMobile', {
    staticClass: "head"
  }) : _vm._e(), _c('router-view'), _vm.$store.state.userAgent == 'PC' && _vm.showFooter ? _c('Footer') : _vm.$store.state.userAgent == 'mobile' && _vm.showFooter ? _c('IndexFooterMobile') : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };