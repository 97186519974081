import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    addUsInfo: null,
    menuActiveIndex: 0,
    userAgent:'123',
  },
  getters: {
    // menuActiveIndex(state){
    //   this.state.menuActiveIndex = 
    // }
  },
  mutations: {
    SET_ADD_US_INFO(state, other) {
      state.addUsInfo = other
    },
    SET_USER_AGENT(state,userAgent){
      state.userAgent = userAgent
    }
  },
  actions: {

  },
  modules: {
  }
})
