var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "site_footer"
  }, [_c('div', {
    staticClass: "address"
  }, [_vm._m(0), _c('div', {
    staticClass: "top2"
  }, [_c('div', {
    staticClass: "menu_list"
  }, [_c('ul', [_vm._l(_vm.menuList, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "menu_item"
    }, [_c('div', {
      staticClass: "page_title",
      on: {
        "click": function ($event) {
          return _vm.jump(item, index, 'top');
        }
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))]), item.child.length > 0 && index < 3 ? _c('span', [_vm.activeIndex == index && _vm.trigger ? _c('i', {
      staticClass: "el-icon-arrow-up"
    }) : _c('i', {
      staticClass: "el-icon-arrow-down"
    })]) : _vm._e()]), _vm.activeIndex == index && _vm.trigger && index <= 2 ? _c('div', {
      staticClass: "page_child_title"
    }, _vm._l(item.child, function (child) {
      return _c('div', {
        key: child.title,
        staticClass: "child_title",
        on: {
          "click": function ($event) {
            return _vm.jump(child, 0, 'child');
          }
        }
      }, [_vm._v(_vm._s(child.title))]);
    }), 0) : _vm._e(), index == 3 ? _c('div', {
      staticStyle: {
        "padding": "0 15px"
      }
    }, [_vm._l(item.child, function (img, imgIndex) {
      return _c('img', {
        key: imgIndex,
        staticClass: "footer_img",
        attrs: {
          "src": img,
          "alt": ""
        },
        on: {
          "click": function ($event) {
            return _vm.showWXImg(imgIndex);
          }
        }
      });
    }), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showBottomImg,
        expression: "showBottomImg"
      }],
      staticClass: "wxs"
    }, [_vm._m(1, true), _vm._m(2, true), _vm._m(3, true)])], 2) : _vm._e()]);
  }), _vm._m(4)], 2)])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "top1"
  }, [_c('div', [_vm._v("联系我们")]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/icons/footer_tel.png"),
      "alt": ""
    }
  }), _c('span', [_c('a', {
    staticStyle: {
      "color": "#fff"
    },
    attrs: {
      "href": "tel:400-653-0288"
    }
  }, [_vm._v("400-653-0288")])])]), _c('div', [_c('span', [_vm._v("邮编：100143")])]), _c('div', [_c('span', [_vm._v("地址：")]), _c('span', [_vm._v("北京市石景山区古城南街")])]), _c('div', [_vm._v("          绿地金融城9号院1号楼5层")]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/fuwuhao.jpg"),
      "alt": ""
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/fuwuhao.jpg"),
      "alt": ""
    }
  }), _c('div', [_vm._v("中建政研服务号")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/dingyue.png"),
      "alt": ""
    }
  }), _c('div', [_vm._v("中建政研订阅号")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/joinUs/shipin.png"),
      "alt": ""
    }
  }), _c('div', [_vm._v("中建政研视频号")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', {
    staticClass: "footer_copyright"
  }, [_c('div', [_vm._v("京ICP备17059785号-1")]), _c('div', [_vm._v("Copyright ©2022 中建政研集团 ALL Rights Reserved.")])]);
}];
export { render, staticRenderFns };