import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      hoverLeftIndex: null,
      list: [{
        icon: require('@/assets/rightLayout/icon_company.png'),
        icona: require('@/assets/rightLayout/icon1-1.png'),
        name: '联系我们'
      }, {
        icon: require('@/assets/rightLayout/icon_phone.png'),
        icona: require('@/assets/rightLayout/icon1-2.png'),
        name: '客服电话'
      }, {
        icon: require('@/assets/rightLayout/icon_weixin.png'),
        icona: require('@/assets/rightLayout/icon1-3.png'),
        name: '微信'
      }, {
        icon: require('@/assets/rightLayout/icon_weibo.png'),
        icona: require('@/assets/rightLayout/icon1-4.png'),
        name: '微博'
      }, {
        icon: require('@/assets/rightLayout/icon_top.png'),
        icona: require('@/assets/rightLayout/icon1-5.png'),
        name: ''
      }],
      hoverIndex: null,
      item1: [{
        usual: require('@/assets/rightLayout/icon4.png'),
        active: require('@/assets/rightLayout/icon4_a.png')
      }, {
        usual: require('@/assets/rightLayout/icon1.png'),
        active: require('@/assets/rightLayout/icon1_a.png')
      }, {
        usual: require('@/assets/rightLayout/icon2.png'),
        active: require('@/assets/rightLayout/icon2_a.png')
      }, {
        usual: require('@/assets/rightLayout/icon3.png'),
        active: require('@/assets/rightLayout/icon3_a.png')
      }]
    };
  },
  methods: {
    hoverLeft(item, index) {
      this.hoverLeftIndex = index;
      // console.log(item)
    },

    showActiveHover(index) {
      this.hoverIndex = index;
      // console.log(index)
    },

    showConnects(index) {
      this.$router.push({
        path: '/connectUsIcons',
        query: {
          tabIndex: index + ''
        }
      });
    },
    scrollTop(item, index) {
      if (index == 0) {
        this.$router.push({
          path: '/connectUsIcons'
        });
      }
      if (index == 3) {
        window.open(['https://weibo.com/zhongjianzhengyan?sudaref=127.0.0.1&display=0&retcode=6102', '_blank']);
      }
      if (index == 4) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }
};