var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "menu_list"
  }, [_c('van-collapse', {
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('van-collapse-item', {
      key: index,
      staticClass: "menu_item",
      attrs: {
        "title": item.title,
        "name": index,
        "is-link": item.child.length > 0
      }
    }, [item.child.length > 0 ? _c('div', {
      staticClass: "page_child_title"
    }, _vm._l(item.child, function (child) {
      return _c('div', {
        key: child.title,
        staticClass: "child_title",
        on: {
          "click": function ($event) {
            return _vm.jump(child);
          }
        }
      }, [_vm._v(_vm._s(child.title))]);
    }), 0) : _vm._e()]);
  }), 1), _vm._l(_vm.menuListNoChildren, function (item, index) {
    return _c('van-cell', {
      key: index,
      class: index == _vm.menuListNoChildren.length - 1 ? 'van-cell-border-bottom-yy' : '',
      attrs: {
        "value": item.title
      },
      on: {
        "click": function ($event) {
          return _vm.jump(item);
        }
      }
    });
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };