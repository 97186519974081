import request from '@/utils/request'
import configs from '@/config'
const {api} = configs[process.env.NODE_ENV]
export const getDetails = (url,method,params) => {
    const req = api.concat(url)
    return request({
        url: req,
        method,
        params
    })
}
export function getZixun(url,method,data){
    const req = api.concat(url)
    // console.info(req)
    return request({
        url: req,
        method,
        data
    })
}

export function getZhuanjia(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

export function outBrands(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

export function ourServe(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}
export function getWebsiteInfo(url,method,params){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        params
    })
}
export function getLiveList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

export function getHomeSwiper(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

// 加入我们
export function getAddUsList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

export function getAddUsListType(url,method,params){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        params
    })
}

export function bannerImg(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

// 联系我们
export function getConnectList(url,method,params){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        params
    })
}
// 提交表单
export function submitForm(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}
// 获取验证码
export function getMa(url,method,params){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        params
    })
}

// 媒体中心
// 新闻动态
export function getNewsCenterList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}
// 智库观点
export function getZhiKuGuandianList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}
// 党群建设
export function getDangQunJianSheList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}
// 集团自媒体
export function getJiTuanZiMeiTiList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

// 创始人介绍
export function getCreateUserInfoList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}
export function getCreateUserInfoLists(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}

// 走进中建政研-创始人介绍-媒体评价
export function getCreateUserMediaList(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}





// banners
export function getBanners(url,method,data){
    const req = api.concat(url)
    return request({
        url: req,
        method,
        data
    })
}