import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "headers"
  }, [_c('div', {
    staticClass: "app-head",
    attrs: {
      "id": "app-head"
    }
  }, [_c('div', {
    staticClass: "heads"
  }, [_c('div', {
    staticClass: "head_menu"
  }, [_c('div', [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": require("@/assets/layouts/head_logo.png"),
      "alt": "logo"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/');
      }
    }
  })]), _c('ul', {
    staticClass: "menus"
  }, [_vm._l(_vm.headList, function (item, index) {
    return _c('li', {
      key: index,
      class: index == _vm.activeIndex ? 'active' : '',
      on: {
        "mouseenter": function ($event) {
          $event.stopPropagation();
          return _vm.mouseEnter(item, index);
        },
        "mouseleave": _vm.mouseLeaveMenu,
        "click": function ($event) {
          if ($event.target !== $event.currentTarget) return null;
          $event.stopPropagation();
          return _vm.clickHead(item, index);
        }
      }
    }, [_c('span', {
      on: {
        "click": function ($event) {
          if ($event.target !== $event.currentTarget) return null;
          $event.stopPropagation();
          return _vm.clickHead(item, index);
        },
        "mouseenter": function ($event) {
          return _vm.mouseEnter(item, index);
        }
      }
    }, [_vm._v(_vm._s(item.menu))]), item.menu == '走进中建政研' && _vm.addDropIndex == index ? _c('div', {
      staticClass: "next_menu",
      style: `display:${_vm.showNextMenu}`
    }, [_vm.headDropMenu.length > 0 ? _c('div', {
      staticClass: "heads_drop",
      on: {
        "mouseleave": function ($event) {
          $event.stopPropagation();
          if ($event.target !== $event.currentTarget) return null;
          return _vm.dropMenuOut.apply(null, arguments);
        }
      }
    }, _vm._l(_vm.headDrop.head_menu1, function (item1, index1) {
      return _c('div', {
        key: item1 + index1,
        class: `head_drop_item`
      }, [_c('div', {
        staticClass: "item_title",
        on: {
          "click": function ($event) {
            return _vm.jumpNext(item1, index1, item);
          }
        }
      }, [_vm._v(" " + _vm._s(item1.title) + " ")])]);
    }), 0) : _vm._e()]) : _vm._e(), item.menu == '集团要闻' && _vm.addDropIndex == index ? _c('div', {
      staticClass: "next_menu"
    }, [_vm.headDropMenu.length > 0 ? _c('div', {
      staticClass: "heads_drop",
      style: `display:${_vm.showNextMenu}`,
      on: {
        "mouseleave": function ($event) {
          $event.stopPropagation();
          if ($event.target !== $event.currentTarget) return null;
          return _vm.dropMenuOut.apply(null, arguments);
        }
      }
    }, _vm._l(_vm.headDrop.head_menu2, function (item2, index2) {
      return _c('div', {
        key: item2 + index2,
        class: `head_drop_item`
      }, [_c('div', {
        staticClass: "item_title",
        on: {
          "click": function ($event) {
            return _vm.jumpNext(item2, index2, item);
          }
        }
      }, [_vm._v(" " + _vm._s(item2.title) + " ")])]);
    }), 0) : _vm._e()]) : _vm._e(), item.menu == '产品集群' && _vm.addDropIndex == index ? _c('div', {
      staticClass: "next_menu"
    }, [_vm.headDropMenu.length > 0 ? _c('div', {
      staticClass: "heads_drop",
      style: `display:${_vm.showNextMenu}`,
      on: {
        "mouseleave": function ($event) {
          $event.stopPropagation();
          if ($event.target !== $event.currentTarget) return null;
          return _vm.dropMenuOut.apply(null, arguments);
        }
      }
    }, _vm._l(_vm.headDrop.head_menu3, function (item3, index3) {
      return _c('div', {
        key: item3 + index3,
        class: `head_drop_item`
      }, [_c('div', {
        staticClass: "item_title",
        on: {
          "click": function ($event) {
            return _vm.jumpNext(item3, index3, item);
          }
        }
      }, [_vm._v(" " + _vm._s(item3.title) + " ")])]);
    }), 0) : _vm._e()]) : _vm._e(), item.menu == '联系我们' && _vm.addDropIndex == index ? _c('div', {
      staticClass: "next_menu"
    }, [_vm.headDropMenu.length > 0 ? _c('div', {
      staticClass: "heads_drop",
      style: `display:${_vm.showNextMenu}`,
      on: {
        "mouseleave": function ($event) {
          $event.stopPropagation();
          if ($event.target !== $event.currentTarget) return null;
          return _vm.dropMenuOut.apply(null, arguments);
        }
      }
    }, _vm._l(_vm.headDrop.head_menu4, function (item4, index4) {
      return _c('div', {
        key: item4 + index4,
        class: `head_drop_item`
      }, [_c('div', {
        staticClass: "item_title",
        on: {
          "click": function ($event) {
            return _vm.jumpNext(item4, index4, item);
          }
        }
      }, [_vm._v(" " + _vm._s(item4.title) + " ")])]);
    }), 0) : _vm._e()]) : _vm._e()]);
  }), _c('li', [_c('span', {
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        $event.stopPropagation();
        return _vm.showRightFixed.apply(null, arguments);
      }
    }
  }, [_vm._v("集团架构")])])], 2)])]), _c('div', {
    staticClass: "fixd_position",
    style: `display:${_vm.showRight ? 'block' : 'none'}`,
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        _vm.showRight = false;
      }
    }
  }, [_c('div', {
    staticClass: "close_icon",
    on: {
      "click": function ($event) {
        _vm.showRight = false;
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-close"
  })]), _c('div', {
    staticClass: "fixed_container"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("集团子品牌")]), _c('ul', {
    staticClass: "icons"
  }, _vm._l(_vm.fixedList, function (item, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.jump(item);
        }
      }
    }, [item.logoImg ? _c('img', {
      attrs: {
        "src": item.logoImg,
        "alt": item.name
      }
    }) : _vm._e()]);
  }), 0), _vm._m(0), _c('ul', {
    staticClass: "groups"
  }, _vm._l(_vm.groupList, function (item, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "head",
      on: {
        "click": function ($event) {
          return _vm.showChild(item, index);
        }
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    }) : _vm._e(), _c('div', [_vm._v(_vm._s(item.name))]), _c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.show,
        expression: "!item.show"
      }],
      staticClass: "el-icon-arrow-down"
    }), _c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.show,
        expression: "item.show"
      }],
      staticClass: "el-icon-arrow-up"
    })]), _vm._l(item.child, function (items, indexs) {
      return _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: item.show,
          expression: "item.show"
        }],
        key: indexs,
        staticClass: "body",
        attrs: {
          "title": items.contentTitle
        },
        on: {
          "click": function ($event) {
            return _vm.clickChildRow(items, item);
          }
        }
      }, [_vm._v(" " + _vm._s(items.contentTitle) + " ")]);
    })], 2);
  }), 0)])]), _c('LayoutRight')], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "line"
  }, [_c('div')]);
}];
export { render, staticRenderFns };