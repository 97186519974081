import "core-js/modules/es.array.push.js";
export default {
  name: 'IndexFooterMobile',
  data() {
    return {
      activeName: '',
      showBottomImg: false,
      activeIndex: 0,
      trigger: false,
      menuList: [{
        title: '走进中建政研',
        path: '',
        child: [{
          title: '集团介绍',
          path: '/enterUs',
          tabs: 1,
          child: []
        }, {
          title: '创始人介绍',
          path: '/createPersonInfo',
          tabs: 1,
          child: []
        }, {
          title: '发展历程',
          path: '/development',
          tabs: 1,
          child: []
        }, {
          title: '资质荣誉',
          path: '/honor',
          tabs: 1,
          child: []
        }, {
          title: '新型智库',
          path: '/viewpoint',
          tabs: 1,
          child: []
        }]
      }, {
        title: '集团要闻',
        path: '',
        child: [{
          title: '新闻动态',
          path: '/mediaCenter',
          btnIndex: '0',
          child: []
        }, {
          title: '党群建设',
          path: '/mediaCenter',
          btnIndex: '1',
          child: []
        }
        // {
        //     title:'集团自媒体',
        //     path:'/mediaCenter',
        //     btnIndex:'2',
        //     child:[]
        // },
        ]
      }, {
        title: '联系我们',
        path: '/connectUsIcons',
        child: []
      }, {
        title: '关注我们',
        path: '',
        child: [require('@/assets/icons/icon_WX.png'), require('@/assets/icons/icon_WB.png')]
      }],
      menuListNoChildren: [{
        title: '联系我们',
        path: '/connectUsIcons',
        child: []
      }]
    };
  },
  methods: {
    // 显示底部微信二维码
    showWXImg(index) {
      if (index == 0) {
        this.showBottomImg = !this.showBottomImg;
      }
      if (index == 1) {
        window.open(['https://weibo.com/zhongjianzhengyan?sudaref=127.0.0.1&display=0&retcode=6102']);
      }
    },
    // 内层跳转
    // 顶层跳转和切换
    jump(item, index, type) {
      if (type == 'top') {
        if (item.child.length == 0) {
          this.$router.push(item.path);
        } else {
          this.trigger = !this.trigger;
          this.activeIndex = index;
        }
      }
      if (type == 'child') {
        if (item.link) {
          window.location.href = item.link;
        } else {
          this.$router.push(item.path);
        }
      }
    }
  }
};