import "core-js/modules/es.array.push.js";
import { getBanners } from "@/api/index.js";
export default {
  data() {
    return {
      activeName: '1',
      activeIndex: 0,
      trigger: false,
      menuList: [{
        title: '走进中建政研',
        path: '',
        child: [{
          title: '集团介绍',
          path: '/enterUs',
          tabs: 1,
          child: []
        }, {
          title: '创始人介绍',
          path: '/createPersonInfo',
          tabs: 1,
          child: []
        }, {
          title: '发展历程',
          path: '/development',
          tabs: 1,
          child: []
        }, {
          title: '资质荣誉',
          path: '/honor',
          tabs: 1,
          child: []
        }, {
          title: '专家智库',
          path: '/viewpoint',
          tabs: 1,
          child: []
        }]
      }, {
        title: '集团要闻',
        path: '',
        child: [{
          title: '新闻动态',
          path: '/mediaCenter',
          btnIndex: '0',
          child: []
        }, {
          title: '行业研判',
          path: '/mediaCenter',
          btnIndex: '1',
          child: []
        }
        // {
        //     title:'集团自媒体',
        //     path:'/mediaCenter',
        //     btnIndex:'2',
        //     child:[]
        // },
        ]
      }],

      menuListNoChildren: [{
        title: '加入我们',
        path: '/addUs'
      }, {
        title: '产品集群',
        path: '/brands'
        // child:[]
      }, {
        title: '联系我们',
        path: '/connectUsIcons'
        // child:[
        //     {
        //         title:'联系我们',
        //         path:'/connectUs',
        //         tabs:1,
        //     }
        // ]
      }]
    };
  },

  created() {
    // this.init()
  },
  methods: {
    // 内层跳转
    // 顶层跳转和切换
    jump(item) {
      this.$emit('jumpHandler');
      if (item.link) {
        window.location.href = item.link;
      } else {
        if (item.path == '/mediaCenter') {
          this.$router.push({
            path: item.path,
            query: {
              tabIndex: item.btnIndex
            }
          });
        } else {
          this.$router.push(item.path);
        }
      }
    },
    init() {
      // 产品集群
      getBanners('/cms/content/list.do', 'post', {
        categoryId: '1524933899461029890'
      }).then(res => {
        this.menuList[2].child = res.data.rows.map(item => {
          if (item && item.contentImg) {
            item.contentImg = JSON.parse(item.contentImg);
          } else {
            item.contentImg = require('@/assets/default_with_font.png');
          }
          if (item && item.contentTitle) {
            this.$set(item, 'title', item.contentTitle || '');
          } else {
            this.$set(item, 'title', '');
          }
          this.$set(item, 'link', item && item.contentDescription || '');
          this.$set(item, 'img', item && item.contentDetails || '');
          return item;
        });
      });
    }
  }
};