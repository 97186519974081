import Head from '@/components/Layout/Head/index.vue';
import HeadMobile from '@/components/Layout/Head/headMobile.vue';
import Footer from '@/components/Layout/Footer/index.vue';
import IndexFooterMobile from '@/components/Layout/Footer/IndexFooterMobile.vue';
export default {
  components: {
    Head,
    Footer,
    HeadMobile,
    IndexFooterMobile
  },
  watch: {
    '$route': {
      handler(to, from) {
        if (to.path == "/menu") {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      showFooter: false
    };
  },
  created() {
    //   console.log(this.$store.state.userAgent,'2')
  }
};