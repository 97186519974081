module.exports = {
  development: { // 开发
    // api: "http://192.168.2.22:8006",
    api: "http://admin.zjzy.org.cn",
    oss: "https://toujianyun.oss-cn-beijing.aliyuncs.com/app/public",
    ossBase: "https://toujianyun.oss-cn-beijing.aliyuncs.com"
  },
  staging: { // 测试
    // api: "http://192.168.2.22:8085",
    api: "http://192.168.2.22:8006",
    oss: "https://toujianyun.oss-cn-beijing.aliyuncs.com/app/public",
    ossBase: "https://toujianyun.oss-cn-beijing.aliyuncs.com"
  },
  production: { // 生产
    // api: "http://8.142.167.13:8085/zjzy",
    // api: "http://8.142.167.13:8006",
    api: "https://zjzy.org.cn/api",
    oss: "https://toujianyun.oss-cn-beijing.aliyuncs.com/app/public",
    ossBase: "https://toujianyun.oss-cn-beijing.aliyuncs.com"
  },
};
