import "core-js/modules/es.array.push.js";
import Menu from "@/views/menuPage.vue";
import LayoutRight from '@/components/FixedLayout/index.vue';
export default {
  props: ['showFixed'],
  components: {
    LayoutRight,
    Menu
  },
  data() {
    return {
      menushow: true,
      showRight: false,
      showMenu: true,
      fixedList: [{
        tabIndex: '企/事业单位用户联系',
        icon: require('@/assets/mobile/tab1.png')
      }, {
        tabIndex: '个人用户联系',
        icon: require('@/assets/mobile/tab2.png')
      }, {
        tabIndex: '媒体联系',
        icon: require('@/assets/mobile/tab3.png')
      }, {
        tabIndex: '招聘联系',
        icon: require('@/assets/mobile/tab4.png')
      }]
    };
  },
  // watch:{
  //     '$route':{
  //         handler(to,from){
  //             if(to.path != '/menu'){
  //                 this.showMenu = true
  //             }else{
  //                 this.showMenu = false
  //             }
  //         },
  //         immediate:true
  //     }
  // },
  created() {
    this.init();
  },
  methods: {
    jumpConnects(item) {
      this.menushow = true;
      this.showMenu = true;
      this.$router.push({
        path: '/connectUsIcons',
        query: {
          tabIndex: item.tabIndex
        }
      });
    },
    windowToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    toggleMenu() {
      this.menushow = !this.menushow;
    },
    clickLogo() {
      this.$router.push('/');
      this.showMenu = true;
    },
    jumpHandler() {
      this.showMenu = true;
    },
    changeMenu(status) {
      if (status == 'show') {
        this.showMenu = false;
        // this.$router.push('/menu')
      }

      if (status == 'hidden') {
        this.showMenu = true;
        // this.$router.push('/')
      }
    },

    init() {}
  }
};