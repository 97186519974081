import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// vant 样式文件
import Vant from "vant"
// import { ImagePreview } from 'vant';
// import { Toast } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';
import 'vant/lib/index.css';
// Vue.prototype.ImagePreview = ImagePreview;
// Vue.prototype.Toast = Toast;
// Vue.use(ImagePreview)
// Vue.use(Toast)
Vue.use(Vant)

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// import showDom from './directives/showDom'
// // Vue.directive('domshow', showDom)
// Vue.use(showDom)

// import "@/utils/rem.js"

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import VueImg from "v-img"
const vueImgConfig = {
  //使用`alt`属性作为图库幻灯片标题
  altAsTitle: false,
  //在'close'附近显示'download'按钮，以在新选项卡中打开源图像
  sourceButton: false,
  //打开库的事件监听器将是应用于<img>元素
  openOn: 'click',
  //显示所有具有超过1个图像
  // 缩略图的组的缩略图：false 
}
Vue.use(VueImg, vueImgConfig);


Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
