import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      footerList: [{
        title: {
          name: '走进中建政研',
          path: '/enterUs'
        },
        child: [{
          name: '集团介绍',
          path: '/enterUs'
        }, {
          name: '产品集群',
          path: '/brands'
        }, {
          name: '创始人介绍',
          path: '/createPersonInfo'
        }, {
          name: '发展历程',
          path: '/development'
        }, {
          name: '资质荣誉',
          path: '/honor'
        }]
      }, {
        title: {
          name: '集团要闻',
          path: '/mediaCenter'
        },
        child: [{
          name: '新闻动态',
          path: '/mediaCenter'
        }, {
          name: '行业研判',
          path: '/mediaCenter'
        }
        // {name:'集团自媒体',path:'/mediaCenter'},
        ]
      }, {
        title: {
          name: '联系我们',
          path: '/connectUsIcons'
        },
        child: [{
          name: '企/事业单位用户联系',
          path: '/connectUsIcons',
          tabIndex: '0'
        }, {
          name: '个人用户联系',
          path: '/connectUsIcons',
          tabIndex: '1'
        }, {
          name: '媒体联系',
          path: '/connectUsIcons',
          tabIndex: '2'
        }, {
          name: '招聘联系',
          path: '/connectUsIcons',
          tabIndex: '3'
        }]
      }
      // {title:'关注我们',child:['个人用户联系','媒体联系','招聘联系','企/事业单位用户联系']},
      ]
    };
  },

  methods: {
    jumps(item) {
      let tabIndex;
      if (item.path == '/mediaCenter') {
        if (item.name == '新闻动态') {
          tabIndex = '0';
        }
        if (item.name == '行业研判') {
          tabIndex = '1';
        }
        if (item.name == '集团自媒体') {
          tabIndex = '2';
        }
      }
      if (item.path == '/connectUsIcons') {
        // console.log(item)
        // if(item.name == '个人用户联系'){
        //     tabIndex = '0'
        // }
        // if(item.name == '媒体联系'){
        //     tabIndex = '1'
        // }
        // if(item.name == '招聘联系'){
        //     tabIndex = '2'
        // }
        // if(item.name == '企/事业单位联系'){
        //     tabIndex = '3'
        // }
        tabIndex = item.tabIndex;
      }
      this.$router.push({
        path: item.path,
        query: {
          tabIndex
        }
      });
    },
    jump() {
      window.open(['https://beian.miit.gov.cn']);
    },
    jumpWB() {
      window.open(['https://weibo.com/zhongjianzhengyan?sudaref=127.0.0.1&display=0&retcode=6102']);
    }
  }
};